/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 19.09.2016, 10:45:02
    Author     : Y7 Web - Y7 Group
*/

.clickable-row {
    cursor: pointer;
}
a.btn > span.glyphicon {
    margin-right:8px;
}

/* PANEL HEADINGS */
.panel-default > form > .panel-heading {
    border-bottom:1px solid #d3e0e9;
}
.panel-heading h1,
.panel-heading h2,
.panel-heading h3 {
    margin-top:10px;
}
.panel-heading a.btn {
    margin:8px 15px;
}
.panel-heading a.btn.pull-right,
.panel-heading h2 + .pull-right {
    margin:8px 0px 8px 15px;
}

/* PANEL BODIES */
table .td-label {width:200px;text-align:right;font-weight:bold;}
.col-sm-2 > .toggle.btn {
    width:100% !important;
}

.form-group.no-margin {
    margin-left:0;
    margin-right:0;
}


/* TOBI'S AWESOME STYLES */
.col-sm-2 > button, .col-md-2 > button,
.fullwidth-button > input {
    width:100%;
}
.form-group-submit {
    margin-bottom:0;
}
.form-group-submit > .fullwidth-button {
    margin-top:15px;
}
.panel-body > .row:last-child,
.panel-body > .row.last {
    border-bottom:none !important;
}
.panel-body .row .form-block {
    margin-bottom:0;
}
.form-block.collapsed {
    display:none;
}
a.form-block-trigger::after {
    content:' einblenden';
}
a.form-block-trigger.triggered::after {
    content:' ausblenden';
}
.panel-default .panel-body > .row {
    border-bottom:1px solid #d3e0e9;
}
.form-block {
    padding-bottom:15px;
    margin-bottom:15px;
}
.form-block > h3 {
    color:#3097D1;
    padding-bottom:5px;
    border-bottom:1px dotted #d3e0e9;
}
.form-group > label {
    padding-right:0;
}
.form-group-container {
    border:1px solid #e4e4e4;
    margin-bottom:15px;
}
.form-group-striped {
    padding:7px 15px 8px 15px;
}
.form-group-striped > .form-group {
    margin-bottom:0;
}
.form-group-striped {
    border-bottom:1px solid #e4e4e4;
}
.fform-group-container > div:nth-child(odd) {
   background:#f4f4f4; 
}
.form-group-container > div:nth-child(even) {
   background:#fafafa; 
}
.form-group-container > div:last-child {
    border-bottom:none;
}